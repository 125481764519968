import './home.css';
import * as React from 'react';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import { CardActionArea } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import withAppInsights from '../AppInsights';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import consumption from '../images/consumption.png';
import einvoice from '../images/einvoice.svg';
import index from '../images/index.png';
import location from '../images/location.png';
import sold from '../images/sold.png';
import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'
import calendar from '../images/calendar.png';
import form from '../images/form.png';
import phone from '../images/phone.png';
import facturi from '../images/facturi.png';
import Banner from './banner';


const tiers = [
  {
    title: 'Consumul tău',
    icon: consumption,
  },
  {
    title: 'Sold gaz',
    icon: sold,
  },
  {
    title: 'Facturi',
    icon: facturi,
  },
  {
    title: 'Transmitere index',
    icon: index,
  },
  {
    title: 'Locuri de consum',
    icon: location,
  },
  {
    title: 'Factură electronică',
    icon: einvoice,
  }
];

const indexConsumFunction = (paginaDeDeschis) => { 
    switch (paginaDeDeschis){
      
        case 'Consumul tău': window.location.href='/index-consum';
        break;
        case 'Sold gaz': window.location.href='/sold-rapid';
        break;
        case 'Facturi': window.location.href='/facturi-plati';
        break;
        case 'Transmitere index': window.location.href='/transmitere-index';
        break;
        case 'Locuri de consum': window.location.href='/locuri-de-consum';
        break;
        case 'Factură electronică': window.location.href='/factura-electronica';
        break;
    }
  
  
};

function Home(parametri) {
  let existaLocuriDeConsum = JSON.parse(localStorage.getItem("locuriConsum"));
  // if (parametri.nuAreLocuriDeConsum){
  //   parametri.setNuAreLocuriDeConsum(false);
  //   indexConsumFunction('Locuri de consum');
  // }

  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('xs'));

  const children = [];
    return ( <div className = "App" >
<Container maxWidth="md" component="main"> 
         <Banner/>
        {parametri.locuriConsumUtilizator && parametri.locuriConsumUtilizator.length > 0 ? null : <h3 style={{ marginTop: "60px"}}>Vă rugăm să vă asociați un loc de consum pentru contul dumnevoastră din meniul "Locuri de consum".</h3> }
        <Grid container spacing={isSmall ? 0 : 5} style={{ marginTop: 60}} alignItems="flex-end">
          {tiers.map((tier) => (
            <Grid
              item
              key={tier.title}
              xs={4}
              sm={6}
              md={4}
              
            >
              <Card onClick={() => indexConsumFunction(tier.title)}>
              <CardActionArea>
                <CardHeader
                  title={tier.title}
                  sx={{
                    backgroundColor: (theme) =>
                      theme.palette.mode === 'light'
                        ? theme.palette.grey[200]
                        : theme.palette.grey[700],
                        
                  }}
                  style={{minHeight:'4rem'}}
                />
                <CardContent>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'baseline',
                      mb: 2,
                    }}
                  >

                    <img className="imagini" style={{height: 70}} src={tier.icon} />

                  </Box>
                </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          ))}
        </Grid>
        {children}
      </Container>
      <Box 
      component="footer"
      sx={{

        py: 20,
        px: 30,
        mt: 'auto',
        backgroundColor: (theme) =>
          theme.palette.mode === 'light'
            ? theme.palette.grey[200]
            : theme.palette.grey[800],

      }}
      >
          <Divider variant="middle" style={{color: '#1e456d', marginBottom: "1em",backgroundColor: "#1e456d"}} />
            <Container style={{maxWidth:"80%",display:"flex", justifyContent:"space-between", color:"#1e456d"}}>
              <Typography variant="body3" component="body2">
              <img className="imagini" style={{height: 25, verticalAlign:"middle"}} src={phone} /> Call Center: <b><u>021 231 10 21</u></b>
              </Typography>
              <Typography variant="body3" component="body2">
              <img className="imagini" style={{height: 25, verticalAlign:"middle"}} src={calendar} /> Program Call Center: <b>Luni - Vineri:  09:00 - 17:30 </b> <br /> <b>Sâmbătă: 10:00 - 17:00</b>
              </Typography>
              <Typography variant="body3" component="body2">
              <img className="imagini" style={{height: 25, verticalAlign:"middle"}} src={form} /> <a href="https://www.premierenergy.ro/contact">Contact</a>
              </Typography>
            </Container>
        </Box>
        
        </div >
    );
}

export default withAppInsights(Home);