import React, { useState, useEffect } from "react";
import { Box, Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";

const Banner = () => {
  const [expanded, setExpanded] = useState(true); 
  const theme = useTheme(); 
  const isMobile = useMediaQuery(theme.breakpoints.down("sm")); 

  
  useEffect(() => {
    const timer = setTimeout(() => {
      setExpanded(false);
    }, 15000);
    return () => clearTimeout(timer);
  }, [expanded]);

  const handleMouseOver = () => !isMobile && setExpanded(true); 
  const handleTap = () => setExpanded(true);

  
  const styles = {
    container: {
      position: "flex-end",
      bottom: 0,
      left: 0,
      right: 0,
      
      margin: isMobile ? "0.5rem" : "1rem", // Margină diferită pe mobile
      marginTop: "60px",
      padding: expanded ? "2rem" : "1rem",
      backgroundColor: "#ff9241",
      color: "white",
      borderRadius: "8px",
      boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
      transition: "all 0.3s ease", // Efect de tranziție
      cursor: isMobile ? "default" : "pointer", // Cursor diferit pe mobil
      textAlign: "center",
      overflow: "hidden",
    },
    title: {
      fontSize: expanded ? "1.25rem" : "1rem",
      fontWeight: "bold",
    },
    content: {
      fontSize: expanded ? "1rem" : "0",
      opacity: expanded ? 1 : 0,
      height: expanded ? "auto" : 0,
      transition: "opacity 0.3s ease, height 0.3s ease",
    },
  };

  return (
    <Box
      sx={styles.container}
      onMouseOver={handleMouseOver}
      onClick={handleTap}
    >
      {/* Titlul banner-ului */}
      <Typography sx={styles.title}>
        Divizarea activităților de furnizare și distribuție
      </Typography>

      {/* Conținutul banner-ului */}
      <Typography sx={styles.content}>
        Dragă client, te informăm că ne aflăm în proces de divizare pentru separarea activităților de furnizare și
        distribuție a gazelor naturale. Te informăm că denumirea societății a fost modificată, iar factura va fi emisă de
        NeoGas Grid S.A, în perioada ianuarie-februarie 2025. Datele de identificare ale companiei emitente au rămas
        neschimbate. Vă mulțumim că ați ales să faceți parte din Grupul Premier Energy!
      </Typography>
    </Box>
  );
};

export default Banner;
