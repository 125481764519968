export const consumPartener = 

{
    "pa":"1234567890",
     "Nume/Denumire client":"Ionescu",
     "locuriConsum":[{
         "codlocConsum":"PE1212",
         "adresaLocConsum":"adresa multi line ",
         "contracte":[{
              "nrContractFurnizare":"3000000001",
              "dataContractFurnizare":"2021-09-01",
              "consum":[{
                     "dataCitire":"2021-08-01",
                     "consumM3":100,
                     "consumMWH":1.000
              },
              {
                     "dataCitire":"2021-07-01",
                     "consumM3":200,
                     "consumMWH":2.000
          }]
         }]
     },
     {
        "codlocConsum":"PE1213",
        "adresaLocConsum":"adresa multi line ",
        "contracte":[{
              "nrContractFurnizare":"3000000002",
              "dataContractFurnizare":"2021-09-01",
              "consum":[{
                     "dataCitire":"2020-08-01",
                     "consumM3":300,
                     "consumMWH":3.000
              },
              {
                     "dataCitire":"2020-07-01",
                     "consumM3":400,
                     "consumMWH":4.000
          }]
        }]
    }
    ]
};